import React from 'react';
import { Col, Row } from 'reactstrap';
import Layout from '../components/layouts/Layout';
import { ResetPassword } from '../components/Login';

const ResetPasswordPage = () => {
  return (
    <Layout>
      <header className="header">
        <div className="header_carousel">
          <Row>
            <Col
              xs={{ size: 10, offset: 1 }}
              md={{ size: 6, offset: 3 }}
              lg={{ size: 4, offset: 4 }}
            >
              <ResetPassword />
            </Col>
          </Row>
        </div>
      </header>
    </Layout>
  );
};

export default ResetPasswordPage;
